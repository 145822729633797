import React, { useContext, useState } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';

import SEO from '../../components/seo';
import Layout from '../../components/layout';
import AccountSignup from '../../components/accountIuput/accountSignup';
import { getDeviceToken } from '../../util/util';

const url = process.env.GATSBY_API_URL;

const Signup = ({ location }) => {
  const [signupErrorMessage, setSignupErrorMessage] = useState('');
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const signup = async ({ email, password, checkPassword }) => {
    localStorage.removeItem('deviceToken');
    setSignupErrorMessage('');
    setSignupSuccess(false);
    setIsLoading(true);
    if (password.length < 6 || password.length > 18) {
      setSignupErrorMessage('請輸入 6 - 18 位數密碼');
      setIsLoading(false);
      return;
    }
    if (password !== checkPassword) {
      setSignupErrorMessage('確認密碼不正確');
      setIsLoading(false);
      return;
    }
    const deviceToken = getDeviceToken();
    const authSignupToken = await fetch(`${url}/api/v2/auth/login`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        deviceToken,
        devicePlatform: 'android',
      }),
    })
      .then(async result => {
        const jsonResult = await result.json();
        if (result.ok) {
          return jsonResult.data.accessToken;
        }
      })
      .catch(error => {
        throw error;
      });

    let callback = `${location.origin}/account/login`;
    const { partnerCode } = location.state;
    if (partnerCode) {
      callback = `${callback}?pcode=${partnerCode}`;
    }
    await fetch(`${url}/api/v2/auth/set/account`, {
      method: 'post',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        authorization: `Bearer ${authSignupToken}`,
        'accept-language': 'zh',
      },
      body: JSON.stringify({
        email,
        password,
        callback,
      }),
    })
      .then(async result => {
        setIsLoading(false);
        const jsonResult = await result.json();
        if (result.ok) {
          setSignupSuccess(true);
        } else {
          const { errorCode } = jsonResult;
          if (errorCode === 300204) {
            setSignupErrorMessage('綁定信箱重複');
          } else if (errorCode === 300205) {
            setSignupErrorMessage('使用者已設定過帳號');
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
        // console.log('Log: error', error);
      });
  };

  return (
    <Layout
      showHamburgerBtn={false}
      showAccountBtn={false}
      showHeader={true}
      contentStyle={{
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url("/images/background.jpg")`,
      }}
    >
      <SEO title="登入" />
      <div>
        {signupSuccess ? (
          <SweetAlert
            success
            title="註冊成功"
            onConfirm={() => setSignupSuccess(false)}
            timeout={2000}
            confirmBtnText="OK"
            confirmBtnBsStyle="primary"
            confirmBtnStyle={{
              backgroundColor: '#5e20bf',
              padding: '10px',
              color: '#fff',
              textDecoration: 'none',
              borderRadius: '20px',
              minWidth: '100px',
            }}
          >
            請去信箱收信
          </SweetAlert>
        ) : null}
        <AccountSignup
          signup={signup}
          signupErrorMessage={signupErrorMessage}
          isLoading={isLoading}
        />

        <style jsx>{`
          div {
            display: flex;
            justify-content: center;
          }
        `}</style>
      </div>
    </Layout>
  );
};

export default Signup;
