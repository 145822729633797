import React, { Component, useState } from 'react';

import Input from '../input';
import Button from '../button';

const AccountSignup = ({ signup, signupErrorMessage, isLoading = false }) => {
  const [signupEmail, setSignupEmail] = useState('');
  const [signupPassword, setSignupPassword] = useState('');
  const [signupCheckPassword, setSignupCheckPassword] = useState('');

  return (
    <div className="container">
      <div
        style={{
          marginLeft: 75,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <img
          style={{ height: 41, width: 41 }}
          src="/images/herehear_logo.png"
        ></img>
        <p style={{ fontSize: 28, fontWeight: 500, paddingLeft: 10 }}>註冊</p>
      </div>
      <div className="input-panel">
        <div className="message-container">
          <p className="title message">請設定一組電子信箱/密碼。</p>
          <p
            className="message"
            style={{ paddingBottom: 20, color: '#6924d6' }}
          >
            建議提供常用電子信箱以取得認證信，完成註冊。
          </p>
        </div>
        <Input
          value={signupEmail}
          onChange={event => {
            setSignupEmail(event.target.value);
          }}
          placeholder="example@gmail.com"
          type="text"
        />
        <Input
          value={signupPassword}
          onChange={event => {
            setSignupPassword(event.target.value);
          }}
          placeholder="6 - 18 位數密碼"
          type="password"
          tips="請區分大小寫"
        />
        <Input
          value={signupCheckPassword}
          onChange={event => {
            setSignupCheckPassword(event.target.value);
          }}
          placeholder="再次輸入密碼確認"
          type="password"
          errorMessage={signupErrorMessage}
        />
        <div className="btn-container">
          <Button
            text="確定"
            isLoading={isLoading}
            onClick={() => {
              signup({
                email: signupEmail,
                password: signupPassword,
                checkPassword: signupCheckPassword,
              });
            }}
          />
        </div>
      </div>
      <style jsx>{`
        .container,
        .input-panel {
          width: 100%;
          min-width: 300px;
        }
        @media (min-width: 576px) {
          .container,
          .input-panel {
            width: 414px;
          }
        }
        // Medium devices (tablets, 768px and up)
        @media (min-width: 768px) {
        }

        // Large devices (desktops, 992px and up)
        @media (min-width: 992px) {
        }

        // Extra large devices (large desktops, 1200px and up)
        @media (min-width: 1200px) {
        }

        .message-container {
          margin-left: 60px;
          margin-right: 60px;
        }
        .container {
          padding-top: 76px;
        }
        .input-panel {
          border-radius: 15px;
          top: -15px;
          padding-top: 26px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .message {
          max-width: 375px;
          font-size: 16px;
          text-align: left;
          color: #363636;
          line-height: 1.5;
        }
        .btn-container {
          margin-bottom: 20px;
        }
      `}</style>
    </div>
  );
};

export default AccountSignup;
